import logo from '../../assets/images/logo.jpg'

const BusinessInfo = {
    name: "Sawda Enterprise",
    fullName: "M/S Sawda Enterprise",
    dealer: "(Authorized Dealer Of ACI Motors Limited)",
    address: "TS Tower, Mawna Chowrasta, Sreepur, Gazipur",
    mobile: "Mobile: 01404010529,01404010530",
    img: logo,
}

export default BusinessInfo;