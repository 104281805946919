import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { json, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../Shared/Loader';

const SubmitBooking = () => {
    const [services, setServices] = useState([]);
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const timeSlot = useSelector((state) => state.booking.slot[0]);
    const { customer } = useSelector((state) => state?.booking);
    const [serviceName, setServiceName] = useState('');
    const [isService, setIsService] = useState(false);
    const [error, setError]=useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetch("https://servers.thakurgaonexpress.com/yamahase/getservices")
            .then(res => res.json())
            .then(data => {
                setServices(data);
            })
    }, [])

    const handleService = () => {
        setIsService(true);
    }


    const addBooking = (data) => {
        if (isService) {
            fetch("https://servers.thakurgaonexpress.com/yamahase/addbooking", {
                method: "POST",
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                    toast('Your Booking Confirmed')
                   
                });
            reset();
            

            const token = `92310756091678413369ac9dc34ff7599524a6ea84a77094beb1`;
            const number = data.person_phone;
            const msg = `প্রিয় ${data.person_name}, এনবিসি মটরস মাওনা হোন্ডা শোরুমে সার্ভিস বুকিং করার জন্য আপনাকে ধন্যবাদ। আপনার বুকিংকৃত ${data.service} এর তারিখ ${data.booking_date} সময় ${data.time_slot}, প্রয়োজনে যোগাযোগ 01958-486257`;
            const message = encodeURI(msg)

            const url = `https://api.greenweb.com.bd/api.php?token=${token}&to=${number}&message=${message}`

            fetch(url)
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                })

                setTimeout(()=>{navigate('/')}, 4000);


        } else {
            setError("Please Select Your Service Name");
        }
    }

    useEffect(() => {
        const selectedDate = customer?.purchaseDate;
        const today = new Date();
        const purchaseDate = new Date(selectedDate);
        const milisecond = today - purchaseDate;
        const days = Math.ceil(milisecond / (1000 * 3600 * 24));

        if (days > 1 && days < 30) {
            setServiceName('1st Free Service');
        }
        if (days > 30 && days < 120) {
            setServiceName('2nd Free Service');
        }
        if (days > 120 && days < 240) {
            setServiceName('3rd Free Service');
        }
        if (days > 240 && days < 365) {
            setServiceName('1st Paid Service');
        }
        if (days > 365 && days < 485) {
            setServiceName('4th Free Service');
        }
        if (days > 485 && days < 605) {
            setServiceName('2nd Paid Service');
        }
        if (days > 605 && days < 730) {
            setServiceName('5th Free Service');
        }
        if (days > 730 && days < 840) {
            setServiceName('3rd Paid Service');
        }
        if (days > 840 && days < 960) {
            setServiceName('4th Paid Service');
        }
        if (days > 960 && days < 1080) {
            setServiceName('5th Paid Service');
        }
        if (days > 1080 && days < 1200) {
            setServiceName('6th Paid Service');
        }
        if (days > 1200 && days < 1320) {
            setServiceName('7th Paid Service');
        }
        if (days > 1320 && days < 1440) {
            setServiceName('8th Paid Service');
        }
        if (days > 1440 && days < 1560) {
            setServiceName('9th Paid Service');
        }
        if (days > 1560 && days < 1680) {
            setServiceName('10th Paid Service');
        }
        if (days > 1680 && days < 1800) {
            setServiceName('11th Paid Service');
        }
        if (days > 1800) {
            setServiceName('Master Service');
        }
    }, [])
    // const handleServiceType = (event) => {
    //     const selectedDate = event.target.value;
    //     const today= new Date();
    //     const purchaseDate= new Date (selectedDate);
    //     const milisecond= today-purchaseDate;
    //     const days=Math.ceil(milisecond / (1000 * 3600 * 24));

    //     if(days>1 && days<30){
    //         setServiceName('1st Free Service');
    //     }
    //     if(days>30 && days<120){
    //         setServiceName('2nd Free Service');
    //     }
    //     if(days>120 && days<240){
    //         setServiceName('3rd Free Service');
    //     }
    //     if(days>240 && days<365){
    //         setServiceName('1st Paid Service');
    //     }
    //     if(days>365 && days<485){
    //         setServiceName('4th Free Service');
    //     }
    //     if(days>485 && days<605){
    //         setServiceName('2nd Paid Service');
    //     }
    //     if(days>605 && days<730){
    //         setServiceName('5th Free Service');
    //     }
    //     if(days>730 && days<840){
    //         setServiceName('3rd Paid Service');
    //     }
    //     if(days>840 && days<960){
    //         setServiceName('4th Paid Service');
    //     }
    //     if(days>960 && days<1080){
    //         setServiceName('5th Paid Service');
    //     }
    //     if(days>1080 && days<1200){
    //         setServiceName('6th Paid Service');
    //     }
    //     if(days>1200 && days<1320){
    //         setServiceName('7th Paid Service');
    //     }
    //     if(days>1320 && days<1440){
    //         setServiceName('8th Paid Service');
    //     }
    //     if(days>1440 && days<1560){
    //         setServiceName('9th Paid Service');
    //     }
    //     if(days>1560 && days<1680){
    //         setServiceName('10th Paid Service');
    //     }
    //     if(days>1680 && days<1800){
    //         setServiceName('11th Paid Service');
    //     }
    //     if(days>1800){
    //         setServiceName('Master Service');
    //     }
    // }

    return (
        <div>
            <div className='flex justify-center items-center flex-col'>
                <h1 className='mt-8 mb-4 text-xl border-b-4 border-red-600 inline-block mt-20'>Fill Up Your Booking Informations</h1>
            </div>
            <form onSubmit={handleSubmit(addBooking)} className='flex justify-center items-center flex-col'>
                <div class="form-control w-80">
                    <label class="label">
                        <span class="label-text">Booking Date</span>
                    </label>
                    <input  {...register("booking_date", { required: true })} value={timeSlot?.date} type="text" class="input input-bordered w-full max-w-xs" />
                </div>
                <div class="form-control w-80">
                    <label class="label">
                        <span class="label-text">Time Slot</span>
                    </label>
                    <input  {...register("time_slot", { required: true })} value={timeSlot?.slot} type="text" class="input input-bordered w-full max-w-xs" />
                </div>
                <div class="form-control w-80">
                    <label class="label">
                        <span class="label-text">Your Bike Purchase Date </span>
                    </label>
                    <input  {...register("purchase_date", { required: true })} value={customer?.purchaseDate} type="date" placeholder="Date" class="input input-bordered w-full max-w-xs" required />
                </div>
                {
                    serviceName ? <div class="form-control my-2 w-80">
                        <label class="label">
                            <span class="label-text font-bold">Your Recomended Service: {serviceName}</span>
                        </label>
                    </div> : ''
                }
                <div class="form-control w-80">
                    <label class="label">
                        <span class="label-text text-error font-bold">Select Service Name</span>
                    </label>
                    <select onClick={handleService} {...register("service", { required: true })} class="select select-bordered w-full max-w-xs" required>
                        {
                            services.map(service => <option>{service.name}</option>)
                        }
                    </select>
                </div>
                <div class="form-control w-80">
                    <label class="label">
                        <span class="label-text">Your Name</span>
                    </label>
                    <input  {...register("person_name", { required: true })} value={customer?.name} type="text" placeholder="Enter Your Name" class="input input-bordered w-full max-w-xs" required />

                </div>
                <div class="form-control w-80">
                    <label class="label">
                        <span class="label-text">Your Phone No <span className='text-error font-bold'>(Change If Required)</span></span>
                    </label>
                    <input  {...register("person_phone", { required: true })} defaultValue={customer?.phone} type="text" placeholder="Enter Your Number" class="input input-bordered w-full max-w-xs" required />

                </div>
                <div class="form-control w-80">
                    <label class="label">
                        <span class="label-text">Bike Model</span>
                    </label>
                    <input  {...register("model", { required: true })} value={customer?.model} type="text" placeholder="Enter Bike Model" class="input input-bordered w-full max-w-xs" required />
                </div>
                <div class="form-control w-80">
                    <label class="label">
                        <span class="label-text">Chassis No</span>
                    </label>
                    <input  {...register("chassis", { required: true })} value={customer?.chassis} type="text" placeholder="Enter Bike Model" class="input input-bordered w-full max-w-xs" required />
                </div>
                <div class="form-control w-80">
                    <label class="label">
                        <span class="label-text text-error font-bold">Description</span>
                    </label>
                    <textarea  {...register("description")} class="textarea textarea-bordered" placeholder="Write Problem In Details"></textarea>
                </div>
                {
                    error&& <p className='my-3 text-error font-bold'>{error}</p>
                }
                <button className='btn btn-md btn-error text-white my-4 rounded-md' type='submit'>Confirm Booking</button>
            </form>
        </div>
    );
};

export default SubmitBooking;