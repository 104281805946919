import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { addToSalesInvoice } from '../../../../features/SalesSlice/SalesSlice';
import useCurrentStock from '../../../../hooks/useCurrentStock';
import Loader from '../../../Shared/Loader';
import UpdateModal from './UpdateModal';



const AddSalesProductList = ({ setAudioData }) => {
    const [searchResult, setSearchResult] = useState([]);
    // const [isSearching, setIsSearching] = useState(false);
    const [updateProduct, setUpdateProduct] = useState(null);
    // const [searchText, setSearchText] = useState();
    const { isSearching, setIsSearching, searchTerm, handleSearchTermChange, filtered, isLoading } = useCurrentStock();

    console.log(filtered);

    // console.log(filtered)

    const dispatch = useDispatch();


    // if (isLoading) {
    //     return <Loader />
    // }


    // const handleSearchResult = (event) => {
    //     const searchText = event.target.value.toLowerCase(); // Convert search text to lowercase
    //     const match = filtered.filter(product =>
    //         product.name.toLowerCase().includes(searchText) ||
    //         product.code.includes(searchText)
    //     );
    //     setSearchText(searchText); // Update search text state
    //     setSearchResult(match);
    //     setIsSearching(true);
    // }

    // const handleSearchResult = (event) => {
    //     setSearchText(event.target.value)
    //     const match = filtered.filter(product => product.name.includes(searchText) ||
    //         product.name.toLowerCase().includes(searchText) || product.name.toUpperCase().includes(searchText) ||
    //         product.code.includes(searchText) || product.code.toLowerCase().includes(searchText) ||
    //         product.code.toUpperCase().includes(searchText));
    //     setSearchResult(match);
    //     setIsSearching(true);
    // }


    const handleAddProductToInvoice = (product) => {
        dispatch(addToSalesInvoice(product));
        setUpdateProduct(product);
        setIsSearching(false);
    }

    return (
        <div className='w-10/12 lg:w-11/12 mx-auto relative text-xs  z-50'>
            <h1 className='text-lg border-b-4 border-dark-purple font-bold mt-8 inline-block'>Select Product</h1><br />
            <div className='flex justify-left items-center my-4'>
                <div class="form-control w-80">
                    <input onChange={handleSearchTermChange} value={searchTerm} type="text" placeholder="Search Product" class="input input-bordered border-dark-purple w-full text-xs max-w-xs" />
                </div>
            </div>

            <hr />

            <div style={isSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 bg-gray-200 p-2 ${isSearching ? 'h-60' : "h-8"} w-80 overflow-auto z-20`}>
                {
                    // isSearching ? searchResult.map(product =>
                    //     <label for="update-modal" onClick={() => handleAddProductToInvoice(product)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{product?.name}</label>) :
                    filtered.map(product =>
                        <label for="update-modal" onClick={() => handleAddProductToInvoice(product)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{product?.name}</label>)
                }
            </div>
            <div className='w-8/12'>
                {
                    updateProduct && <UpdateModal setUpdateProduct={setUpdateProduct} updateProduct={updateProduct}></UpdateModal>
                }
            </div>
        </div >
    );
};

export default AddSalesProductList;