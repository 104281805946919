import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Shared/Loader';
import { useRef } from 'react';
import AddSalesProductList from './AddSalesProductList';
import AddSalesInvoice from './AddSalesInvoice';
import { selectCustomer } from '../../../../features/SalesSlice/SalesSlice';
import { format } from 'date-fns';
import useFetchData from '../../../../hooks/useFetchData';
import CustomerList from '../../../../hooks/CustomerList';



const AddBikeSales = ({ }) => {
    const { posCustomer,isCustomer } = useSelector((state) => state.salesInvoice);
    const { items } = useFetchData('https://servers.thakurgaonexpress.com/yamahase/getsalesinvoice');
    const [isSearching, setIsSearching] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [searchText, setSearchText] = useState([]);
    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [currentCustomer, setCurrentCustomer] = useState([]);
    const [details, setDetails] = useState();
    const [isInvoice, setIsInvoice] = useState(false);
    // const [isCustomer, setIsCustomer] = useState(false);
    const date = new Date();
    const today = format(date, "yyyy-MM-dd");

    console.log(isCustomer);

    const customerCodeRef = useRef('');
    const customerRef = useRef('');
    const addressRef = useRef('');
    const phoneRef = useRef('');
    const openingRef = useRef('');
    const dateRef = useRef('');
    const dispatch = useDispatch();



    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/yamahase/getmaincustomers')
            .then(res => res.json())
            .then(data => {
                setCustomers(data);
                setLoading(false)
            })
    }, [])


    if (loading) {
        return <Loader />
    }

    const handleSearchResult = (event) => {
        setSearchText(event.target.value)
        const match = customers.filter(customer => customer.name.includes(searchText)
            || customer.name.toLowerCase().includes(searchText) ||
            customer.name.toUpperCase().includes(searchText));
        setSearchResult(match);
        setIsSearching(true);
    }

    const handleSelectCustomer = (customer) => {
        const opening = parseFloat(customer?.opening)
        const url = `https://servers.thakurgaonexpress.com/yamahase/filteredmaincustomer?code=${customer?.code}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                console.log(data);
                const due = opening + data?.defaultTotalDue - data?.defaultTotalCollect;
                setCurrentCustomer({ ...customer, due });
            })
        setIsSearching(false)
        setSearchText('');
        // setIsCustomer(true);
    }


    const handleSalesDetails = (event) => {
        const salesInvoice = event.target.value;
        const customerCode = customerCodeRef.current.innerText;
        const salesCustomer = customerRef.current.innerText;
        const salesAddress = addressRef.current.innerText;
        const salesPhone = phoneRef.current.innerText;
        const salesOpening = parseFloat(openingRef.current.innerText);
        const salesDate = dateRef.current.value;
        const isExist = items?.filter(memo => memo?.salesInvoice === salesInvoice);
        const duplicate = isExist?.length ? true : false;

        setIsInvoice(true);
        setDetails({ salesInvoice, customerCode, salesCustomer, salesAddress, salesPhone, salesOpening, salesDate, duplicate })
    }


    return (
        <div className='w-full px-5 pb-5 my-5 text-xs'>
            <h1 className='text-center bg-red-600 p-3 text-white text-lg rounded-xl'>Add A Sales</h1>
            <div className='grid grid-cols-1 lg:grid-cols-12 gap-4'>
                <div class="form-control w-full lg:w-60 max-w-xs mt-5 col-span-2">
                    <div class="form-control lg:w-60 mx-auto">
                        <CustomerList customers={customers} />
                        {/* <input onChange={handleSearchResult} value={searchText} type="text" placeholder="Search Curstomer" class="rounded-lg bg-gray-300 p-3  text-center  focus:border-blue-500 focus:outline-none w-full max-w-xs" />
                    </div>
                    <div style={isSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 bg-gray-200 p-2 ${isSearching ? 'h-16' : "h-8"} w-80 overflow-auto`}>
                        {
                            isSearching ? searchResult.map(customer =>
                                <label for="update-modal" onClick={() => handleSelectCustomer(customer)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{customer?.name}</label>) :
                                customers?.map(customer =>
                                    <label for="update-modal" onClick={() => handleSelectCustomer(customer)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{customer?.name}</label>)
                        } */}
                    </div>
                </div>
                <div className='lg:col-span-6 mt-4 overflow-auto lg:overflow-visible'>
                    <table class="table lg:w-1/2 lg:ml-32">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>Customer Code</th>
                                <th className='bg-red-600 text-white normal-case'>Customer Name</th>
                                <th className='bg-red-600 text-white normal-case'>Customer Address</th>
                                <th className='bg-red-600 text-white normal-case'>Mobile No</th>
                                <th className='bg-red-600 text-white normal-case'>Previews Due</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            <tr className=''>
                                <td ref={customerCodeRef} className='h-8'>{posCustomer?.code}</td>
                                <td ref={customerRef} className='h-8'>{posCustomer?.name}</td>
                                <td ref={addressRef}>{posCustomer?.address}</td>
                                <td ref={phoneRef}>{posCustomer?.phone}</td>
                                <td ref={openingRef}>{(posCustomer?.due)?.toFixed(2)}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <br />
                <div className='lg:col-span-4 mt-4'>
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>Select Date</th>
                                <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            <tr className=''>
                                <td><input ref={dateRef} defaultValue={today} type="date" className='rounded-lg bg-gray-200 p-3  text-center placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs' /></td>
                                <td><input disabled={!isCustomer} onChange={handleSalesDetails} type="text" defaultValue={"Inv-0001"} className='rounded-lg bg-gray-200 p-3 w-40 text-center placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs' /></td>
                            </tr>
                            {details?.duplicate && <tr className=''>
                                <td></td>
                                <td className='text-red-600 font-bold'>(Duplicate Please Change)</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='grid grid-cols-1 relative'>
                <AddSalesProductList />
                <AddSalesInvoice details={details} isInvoice={isInvoice} />
            </div>
        </div>
    );
};

export default AddBikeSales;