import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { removeProductFromList } from '../../../../features/SalesSlice/SalesSlice';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import useFetchData from '../../../../hooks/useFetchData';

const AddSalesInvoice = ({ details, isInvoice }) => {
    const userAc = useSelector((state) => state.user.user);
    const { productList } = useSelector((state) => state.salesInvoice);
    const [dueAmount, setDueAmount] = useState();
    const [totalAmount, setTotalAmount] = useState();
    const [isPayment, setIsPayment] = useState(false);
    const [isDiscount, setIsDiscount] = useState(false);
    const [discount, setDiscount] = useState('');
    const [payment, setPayment] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { items } = useFetchData('https://servers.thakurgaonexpress.com/yamahase/getsalesinvoice');

    const removeFromList = (product) => {
        dispatch(removeProductFromList(product));
    }

    let currentDate = new Date(details?.salesDate);
    // Get the current month and year as strings
    let monthName = currentDate.toLocaleString('default', { month: 'long' });
    let yearName = currentDate.toLocaleString('default', { year: 'numeric' });

    const total = productList.reduce((total, currentValue) => total + parseFloat(currentValue?.total), 0);

    const handleDiscount = (event) => {
        const discount = parseFloat(event?.target?.value);
        setDiscount(discount);
        // const grand = total - discount;
        // setTotalAmount(grand);
        setIsDiscount(true);
    }
    const handlePayment = (event) => {
        const payment = parseInt(event?.target?.value);
        setPayment(payment);
        // const dueAmount = totalAmount - payment;
        // setDueAmount(dueAmount);
        setIsPayment(true);
        setIsDiscount(true);
    }

    const totalRef = useRef('');
    const discountRef = useRef();
    const grandTotalRef = useRef();
    const paymentRef = useRef();
    const dueAmountRef = useRef();

    const handleSubmitSales = () => {
        const totalAmount = parseFloat(totalRef.current.innerText);
        const discount = parseFloat(discountRef.current.value);
        const grandTotal = parseFloat(grandTotalRef.current.value);
        const paymentdata = parseFloat(paymentRef.current.value);
        const payment = paymentdata ? parseFloat(paymentRef.current.value) : 0;

        const isAdvance = payment === 0 & grandTotal <= Math.abs(details.salesOpening) ? true : false;
        const isPaid = payment + Math.abs(details.salesOpening) >= grandTotal ? true : false;

        const dueData = parseFloat(dueAmountRef.current.value)
        const dueAmount = dueData ? parseFloat(dueAmountRef.current.value) : 0;
        const user = userAc?.email.split('@')[0];
        const month = monthName + " " + yearName;
        const paid = dueAmount === 0 ? true : isAdvance === true ? true : isPaid ? true : false;

        const data = { products: productList, ...details, totalAmount, discount, grandTotal, payment, user, dueAmount, paid, month }

        console.log(data);
        fetch('https://servers.thakurgaonexpress.com/yamahase/sales', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Sales Added Successfull')
                navigate("/managedefaultsales")
                setTimeout(() => { window.location.reload(); }, 2000);
            })

    }
    return (
        <div className='lg:grid lg:grid-cols-12 gap-2 h-screen'>
            <div className='overflow-auto lg:col-span-10 text-xs z-30 mb-4'>
                <div className=''>
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>SL#</th>
                                <th className='bg-red-600 text-white normal-case'>Product Code</th>
                                <th className='bg-red-600 text-white normal-case'>Product Name</th>
                                <th className='bg-red-600 text-white normal-case'>Quantity</th>
                                <th className='bg-red-600 text-white normal-case'>Rate</th>
                                <th className='bg-red-600 text-white normal-case'>Total</th>
                                <th className='bg-red-600 text-white normal-case'>Action</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                productList?.map((product, index) => <tr className='hover'>
                                    <td className='h-8'>{index + 1}</td>
                                    <td className='h-8'>{product?.code}</td>
                                    <td className='text-xs'>{product?.name}</td>
                                    <td><input type="number" value={product?.quantity} placeholder="Type here" className="input text-xs input-bordered text-center h-8 w-20 max-w-lg" /></td>
                                    <td><input type="number" value={product?.rate} placeholder="Type here" className="input text-xs input-bordered text-center h-8 w-28 max-w-lg" /></td>
                                    <td><input type="number" value={product?.total} placeholder="Type here" className="input text-xs input-bordered text-center h-8 w-40 max-w-lg" /></td>
                                    <td> <button onClick={() => removeFromList(product)} className={`btn btn-sm btn-error cursor-pointer`}><AiOutlineClose /></button> </td>
                                </tr>)
                            }

                        </tbody>
                        <tfoot className='text-center h-2'>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Total</td>
                                <td ref={totalRef} className='text-md'>{total.toFixed(2)}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Discount</td>
                                <td> <input ref={discountRef} onChange={handleDiscount} type="number" placeholder="Type Discount" className="input input-bordered text-center text-xs w-48 h-8 mx-auto max-w-lg" /></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div className='overflow-auto lg:col-span-2 ml-20 lg:ml-0'>
                <div style={{ height: '400px' }} className='bg-red-600 w-48  text-xs text-center'>
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>Grand Total</th>
                            </tr>
                        </thead>
                    </table>
                    <input ref={grandTotalRef} type="number" value={isDiscount === true ? (total - discount).toFixed(2) : total.toFixed(2)} placeholder="Type here" className="input text-xs input-bordered text-center w-36 mx-auto max-w-lg" />
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>Cash Payment</th>
                            </tr>
                        </thead>
                    </table>
                    <input ref={paymentRef} onChange={handlePayment} type="number" placeholder="Type Payment" className="input text-xs input-bordered text-center w-36 mx-auto max-w-lg" />
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>Due Amount</th>
                            </tr>
                        </thead>
                    </table>
                    <input ref={dueAmountRef} type="number" value={isDiscount === false ? total.toFixed(2) : isDiscount & isPayment === true ? ((total - discount) - payment).toFixed(2) : ((total - discount) - payment).toFixed(2)} placeholder="Type here" className="input text-xs input-bordered text-center w-36 mx-auto max-w-lg" />
                    <button disabled={!isInvoice | !userAc?.addSales | details?.duplicate} onClick={handleSubmitSales} className='btn btn-sm mt-4 text-white'>Add Sales</button>
                </div>
            </div>
        </div>
    );
};

export default AddSalesInvoice;

//finalAmount === false ? totalAmount : isDue === false ? grandAmount : dueAmount